import { CSSProperties, FC, type MouseEventHandler, PropsWithChildren, ReactNode, useMemo } from 'react'

import { Col, ConfigProvider, List, Row, theme } from 'antd'

import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'

import Text from '..//text/Text'
import { useColor } from '../app'
import IvyIcon from '../icon'
import { Avatar } from '../image/Avatar'
import { useResponsive } from '../screen'

const { useToken } = theme

type ListItemProps = PropsWithChildren<
  Partial<
    {
      className: string
      size: 'small' | 'middle' | 'large'
      bordered: boolean
      gutter?: number
      onClick: () => void
      style?: CSSProperties
    } & typeof List.Item.defaultProps
  >
>

const ListItem: FC<ListItemProps> = ({
  className = '',
  size,
  gutter,
  bordered = false,
  onClick,
  children,
  style,
  ...props
}) => {
  const {
    token: { colorBorder },
  } = useToken()
  const padding = gutter ?? (size === 'small' ? 8 : size === 'middle' ? 12 : 24) / 2

  return (
    <List.Item
      className={classNames(className, onClick ? 'hoverable' : '')}
      style={{
        margin: `0 0 ${bordered ? '0' : '16px'} 0`,
        borderBottom: bordered ? `1px solid ${colorBorder}` : 'none',
        padding: `${bordered ? `${padding}px` : 0} 0`,
        cursor: onClick && 'pointer',
        ...style,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </List.Item>
  )
}

export type ListItemMetaProps = {
  onClick?: MouseEventHandler
  className?: string
  gap?: number
  avatar?: SDK.Components.AvatarProps
  color?: SDK.Components.ColorVariant
  icon?: Design.IvyIcon
  title?: ReactNode
  imagePlacement?: 'left' | 'right'
  siderOnlyImage?: boolean
  subTitle?: ReactNode
  description?: ReactNode
  extra?: ReactNode
  style?: CSSProperties
  image?: FC
  info?: ReactNode
  noWrap?: boolean
  size?: 'small' | 'middle' | 'large'
  align?: 'middle' | 'stretch' | 'bottom' | 'top'
}

const ListItemMeta: FC<PropsWithChildren<ListItemMetaProps>> = ({
  onClick,
  gap = 8,
  className,
  image: DefaultImageComponent,
  imagePlacement = 'left',
  siderOnlyImage = false,
  children,
  avatar: {
    icon: avatarIcon,
    src,
    shape = 'square',
    size: avatarSize = 48,
    placeholder,
    background,
    text,
    color: iconColor,
    ...avatarProps
  } = {},
  icon,
  title,
  color = 'default',
  description = undefined,
  extra,
  subTitle,
  style,
  info,
  size = 'medium',
  align = 'middle',
  noWrap = false,
}) => {
  const { textByVariant } = useColor()
  const iconSize = size === 'small' ? avatarSize / 3 : size === 'medium' ? avatarSize / 2.4 : avatarSize
  const { isMobile } = useResponsive()
  const ImageComponent = useMemo(() => {
    if (DefaultImageComponent) return <DefaultImageComponent />
    if (avatarProps?.children) return avatarProps.children
    if (text && !src) return <Avatar text={text} background={background} shape={shape} size={avatarSize} />
    if (!icon && !avatarIcon && !src && !text && !placeholder) return null
    if (size === 'small' && icon) return <IvyIcon size={iconSize} color={iconColor ? iconColor : 'ghost'} type={icon} />
    if (icon && !src) return <Avatar background={background} icon={icon} shape={shape} size={avatarSize} />

    return (
      <Avatar
        src={src}
        size={avatarSize}
        placeholder={placeholder}
        icon={!text && avatarIcon}
        text={text}
        shape={shape}
        background={background}
        {...avatarProps}
      />
    )
  }, [
    DefaultImageComponent,
    avatarProps,
    text,
    src,
    background,
    shape,
    avatarSize,
    icon,
    avatarIcon,
    placeholder,
    size,
    iconSize,
    iconColor,
  ])
  // TODO: Fix alignment for extra and info
  return (
    <Row
      align={align}
      justify={imagePlacement === 'left' ? 'start' : 'end'}
      style={{
        width: siderOnlyImage ? 'fit-content' : '100%',
        color: textByVariant(color),
        cursor: onClick && 'pointer',
        textAlign: imagePlacement,
        padding: '12px 24px',
        ...style,
      }}
      wrap={false}
      className={classNames(className, onClick ? 'hoverable' : '')}
      onClick={onClick}
      gutter={size === 'small' ? 4 : gap}
    >
      {ImageComponent && (
        <Col flex={'none'} order={imagePlacement === 'left' ? 1 : 2} style={{ zIndex: 1 }}>
          {ImageComponent}
        </Col>
      )}
      {!siderOnlyImage && (
        <Col flex={'auto'} order={imagePlacement === 'left' ? 2 : 1}>
          <Row justify={'space-between'} wrap={true} align={align} gutter={[0, 2]}>
            <Col order={imagePlacement === 'left' ? 1 : 2} span={noWrap ? 16 : undefined}>
              <Row style={{ width: '100%' }} gutter={[0, 2]}>
                {title && typeof title === 'string' ? (
                  <Col span={24}>
                    <Text
                      type={color}
                      strong
                      translate={'no'}
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: noWrap ? 'wrap' : 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {title}
                    </Text>
                  </Col>
                ) : (
                  title
                )}
                {subTitle && (
                  <Col span={24}>
                    {typeof subTitle === 'string' ? (
                      <Text strong type={'secondary'} translate={'no'}>
                        {subTitle}
                      </Text>
                    ) : (
                      subTitle
                    )}
                  </Col>
                )}
                {description && (
                  <Col span={24}>
                    {typeof description === 'string' ? (
                      <Text translate={'no'} rows={2} type={'secondary'}>
                        {sanitizeHtml(description, { allowedTags: [] })}
                      </Text>
                    ) : (
                      description
                    )}
                  </Col>
                )}
              </Row>
            </Col>
            {(extra || info) && (
              <Col
                order={imagePlacement === 'left' ? 2 : 1}
                span={noWrap ? 8 : undefined}
                style={{ textAlign: noWrap ? 'right' : undefined }}
              >
                <Row align={'bottom'} gutter={[0, 2]}>
                  {extra && (
                    <Col span={24}>{typeof extra === 'string' ? <Text type={'secondary'}>{extra}</Text> : extra}</Col>
                  )}
                  {info && (
                    <Col span={24}>
                      <ConfigProvider componentSize={'small'}>
                        {typeof info === 'string' ? (
                          <Text translate={'no'} type={'secondary'}>
                            {info}
                          </Text>
                        ) : (
                          info
                        )}
                      </ConfigProvider>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>{children}</Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export { ListItem, ListItemMeta }
